// TODO: refactor and remove this, we mostly moved to the alternate sanityFetch() function approach created in lib/sanity/client.ts
// see: https://github.com/metmuseum/met-js/issues/888
import { createClient } from '@sanity/client'
import { config } from './sanity.config.js'
import { SANITY_API_TOKEN } from './constants'

export const publicClient = createClient({
	...config,
	// There should be no token in this config, making it an 'unauthenticated client'
	// Unauthenticated clients cannot access documents with _id path of drafts.* (or any other path prefix) 
	token: undefined,
	perspective: "published" // redundantly only shows published content
})

export const privatePreviewClient = createClient({
	...config,
	// The presence of this token makes it an "authenticated client"
	// The authenticated client will always return all documents, which is different than a client with no token (hides drafts.* and other subpaths)
	token: SANITY_API_TOKEN,
	ignoreBrowserTokenWarning: true,
	perspective: "previewDrafts" // dedupes previews and published versions, preferring previews when found
})

export const sanityClient = ({ preview = false }) => preview ? privatePreviewClient : publicClient;
