import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/@sanity+next-loader@1.2.12_next@15.1.4_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwrigh_7ah66zchrhed5nui3uf7tzy3uq/node_modules/@sanity/next-loader/dist/client-components/live-stream.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/@sanity+next-loader@1.2.12_next@15.1.4_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwrigh_7ah66zchrhed5nui3uf7tzy3uq/node_modules/@sanity/next-loader/dist/client-components/live.js");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.1.0_next@15.1.4_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwri_x3pap7jejmz7piizfuthvyg3sq/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.26.3_next@15.1.4_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.4_zzdurkvnmgsstxva6lgnjvvogy/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-sanity@9.8.35_@sanity+client@6.24.3_@sanity+icons@3.5.7_react@19.0.0__@sanity+types@3.69_habsadrpsb77c7rbo4jbrmyyhm/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.4_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.49.1_babel-plugin-_h4w3bzcqnrngoammgfqcfjp4oe/node_modules/next/font/google/target.css?{\"path\":\"helpers/fonts/next-font.ts\",\"import\":\"Inter\",\"arguments\":[{\"variable\":\"--inter\",\"display\":\"swap\",\"adjustFontFallback\":true,\"subsets\":[\"latin\"],\"style\":[\"normal\",\"italic\"],\"preload\":true}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.4_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.49.1_babel-plugin-_h4w3bzcqnrngoammgfqcfjp4oe/node_modules/next/font/local/target.css?{\"path\":\"helpers/fonts/next-font.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--austin\",\"display\":\"swap\",\"adjustFontFallback\":\"Times New Roman\",\"src\":[{\"path\":\"../../assets/fonts/Austin/Austin-Medium-Web.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../assets/fonts/Austin/Austin-MediumItalic-Web.woff2\",\"weight\":\"500\",\"style\":\"italic\"}]}],\"variableName\":\"austin\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.4_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.49.1_babel-plugin-_h4w3bzcqnrngoammgfqcfjp4oe/node_modules/next/font/google/target.css?{\"path\":\"helpers/fonts/next-font.ts\",\"import\":\"Noto_Sans\",\"arguments\":[{\"variable\":\"--noto-sans-cy\",\"adjustFontFallback\":false,\"style\":[\"normal\",\"italic\"],\"weight\":[\"400\",\"500\",\"600\"],\"subsets\":[\"cyrillic\"],\"preload\":false}],\"variableName\":\"notoSansCyrillic\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.4_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.49.1_babel-plugin-_h4w3bzcqnrngoammgfqcfjp4oe/node_modules/next/font/google/target.css?{\"path\":\"helpers/fonts/next-font.ts\",\"import\":\"Noto_Sans_JP\",\"arguments\":[{\"variable\":\"--noto-sans-jp\",\"adjustFontFallback\":false,\"preload\":false}],\"variableName\":\"notoSansJP\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.4_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.49.1_babel-plugin-_h4w3bzcqnrngoammgfqcfjp4oe/node_modules/next/font/google/target.css?{\"path\":\"helpers/fonts/next-font.ts\",\"import\":\"Noto_Sans_KR\",\"arguments\":[{\"variable\":\"--noto-sans-kr\",\"adjustFontFallback\":false,\"preload\":false}],\"variableName\":\"notoSansKR\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.4_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.49.1_babel-plugin-_h4w3bzcqnrngoammgfqcfjp4oe/node_modules/next/font/google/target.css?{\"path\":\"helpers/fonts/next-font.ts\",\"import\":\"Noto_Sans_SC\",\"arguments\":[{\"variable\":\"--noto-sans-sc\",\"adjustFontFallback\":false,\"preload\":false}],\"variableName\":\"notoSansSC\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/assets/icons/exit.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/components/copy-to-clipboard/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/components/global/gtm/index.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/global-styles/global.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/components/preview/preview-bar/preview-bar.module.scss");
