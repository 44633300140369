// TODO: refactor and remove this, we mostly moved to the alternate sanityFetch() function approach created in lib/sanity/client.ts
// see: https://github.com/metmuseum/met-js/issues/888
import { SANITY_DATASET, SANITY_PROJECT_ID, SANITY_API_VERSION } from "./constants"

export const config = {
	/**
	* Find your project ID and dataset in `sanity.json` in your studio project.
	* These are considered “public”, but you can use environment variables
	* if you want differ between local dev and production.
	*
	* https://nextjs.org/docs/basic-features/environment-variables
	**/
	dataset: SANITY_DATASET,
	projectId: SANITY_PROJECT_ID,
	apiVersion: SANITY_API_VERSION, // Learn more: https://www.sanity.io/docs/api-versioning
	/**
	* Set useCdn to `false` if your application require the freshest possible
	* data always (potentially slightly slower and a bit more expensive).
	* Authenticated request (like preview) will always bypass the CDN
	**/
	// useCdn: process.env.NODE_ENV === 'production',
	/* For ISR we want this to always be false, I think */
	useCdn: false,
	// EventSource: /* provide your own event source implementation. Required in browsers to support the above token parameter. */
}

console.log(`Initialized with Sanity Dataset: ${config.dataset}`)
