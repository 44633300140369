import { parseObjectIDFromAbsoluteURL } from "lib/parseObjectIDFromAbsoluteURL";
import { getObjectPagePath } from ".";
import type { IArtworkMinimum, MothraObject, IGraphQLObjectPackageObject, GraphQLObject } from "@metmuseum/types";

/* 
	Merges data from Mothra + TheGraphQL endpoint together.
	An object can be passed into lofiArtworkObjectModel multiple times without mutation.
*/

export const lofiArtworkObjectModel = (object: MothraObject | IGraphQLObjectPackageObject | GraphQLObject | IArtworkMinimum): IArtworkMinimum => {
	const formattedObject = {} as IArtworkMinimum;
	
	//The properties that are found on Mothra and GarphQLObjects
	formattedObject.accessionNumber = object.accessionNumber;
	formattedObject.culture = object.culture;
	formattedObject.date = object.date;
	formattedObject.medium = object.medium;

	//allArtistsFormatted
	if ("allArtistsFormatted" in object) {
		formattedObject.allArtistsFormatted = object.allArtistsFormatted;
	} else if ("artists" in object) {
		formattedObject.allArtistsFormatted = object?.artists?.all?.map(x => x.displayName);
	}

	//contextualLabel
	if ("contextualLabel" in object) {
		formattedObject.contextualLabel = object.contextualLabel;
	}

	//creditLine
	if ("creditLine" in object) {
		formattedObject.creditLine = object.creditLine;
	}

	//dimensions
	if ("dimensions" in object) {
		formattedObject.dimensions = object.dimensions;
	}

	//exhibitionLabels
	if ("exhibitionLabels" in object) {
		formattedObject.exhibitionLabels = object.exhibitionLabels;
	}

	//image
	if ("image" in object) {
		formattedObject.image = object.image;
	} else if ("images" in object) {
		formattedObject.image = object.images?.primary?.mainImageUrl;
	}

	//imageAlt
	if ("imageAlt" in object) {
		formattedObject.imageAlt = object.imageAlt;
	} else if ("images" in object) {
		formattedObject.imageAlt = object.images?.primary?.altText;
	}

	//objectID
	if ("objectID" in object) {
		formattedObject.objectID = object.objectID;
	} else {
		// else it's probably a MothraObject and it probably has an absolute URL
		formattedObject.objectID = parseObjectIDFromAbsoluteURL(object.url)
	}

	//period
	if ("period" in object) {
		formattedObject.period = object.period;
	}

	//primaryArtist
	if ("primaryArtist" in object) {
		formattedObject.primaryArtist = object.primaryArtist;
	} else if ("artists" in object) {
		formattedObject.primaryArtist = object?.artists?.primary?.displayName
	} else if ("artist" in object) {
		formattedObject.primaryArtist = object.artist;
	}

	//primaryArtist
	if ("primaryImageRestricted" in object) {
		formattedObject.primaryImageRestricted = object.primaryImageRestricted;
	} else if ("images" in object) {
		formattedObject.primaryImageRestricted = !object.images?.primary?.openAccess && !!object.images?.primary?.mainImageUrl;
	}

	//title
	if ("title" in object) {
		formattedObject.title = object.title;
	} else if ("titles" in object) {
		formattedObject.title = object.titles?.primary?.title;
	}

	//webLabel
	if ("webLabel" in object) {
		formattedObject.webLabel = object.webLabel;
	}

	if ("url" in object) {
		// make absolute URLs relative because we have more environments than just production, it turns out
		formattedObject.url = object.url?.startsWith("/") ? object.url : new URL(object.url).pathname;
		// formattedObject.url = object.url;
	} else if ("objectID" in object) {
		formattedObject.url = getObjectPagePath(object.objectID)
	}

	return formattedObject;
}
