import { PortableText } from '@portabletext/react';
import ptComponents from "./pt-components";
import MarkdownParser from "./markdown-parser";
import type { PortableTextBlock } from "@portabletext/types";
const RichText = ({
  value
}: IProps) => {
  if (!value) {
    return null;
  } //If no text is passed return null.

  //This is a safety measure for if a string is passed into RichText instead of an array of blocks.
  //This allows us to just use this component regardless of what type of field we have in sanity.
  if (typeof value === "string") {
    return <MarkdownParser>{value}</MarkdownParser>;
  }
  return <PortableText value={value} components={ptComponents as any} data-sentry-element="PortableText" data-sentry-component="RichText" data-sentry-source-file="index.tsx" />;
};
interface IProps {
  value: PortableTextBlock[] | string;
}
export default RichText;