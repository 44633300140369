import Markdown from "markdown-to-jsx";
import { parseEntities } from "parse-entities";
interface Props {
  children: string;
}

/**
 * Do not use this unless your data is legacy data and could contain raw HTML.
 * If you do not need for legacy data, use MarkdownParser
 */
const LegacyOrMarkdownParser = ({
  children
}: Props) => {
  if (typeof children !== "string") {
    return null;
  }
  return <Markdown data-sentry-element="Markdown" data-sentry-component="LegacyOrMarkdownParser" data-sentry-source-file="index.tsx">{parseEntities(children)}</Markdown>;
};
export default LegacyOrMarkdownParser;