import GTMDataLayerPusher from "./gtmDataLayerPusher";
import GTMAnalyticsLogger from "./gtmAnalyticsLogger";

//When on production, this sends custom events to Google Tag Manager
//When on development, this logs custom events to the console

export class GTMEventHandler {
	handler: GTMAnalyticsLogger | GTMDataLayerPusher

	constructor() {
		this.handler = process.env.NEXT_PUBLIC_DISABLE_GTM
			? new GTMAnalyticsLogger()
			: new GTMDataLayerPusher();
	}

	pushToDataLayer(args) {
		this.handler.pushToDataLayer(args);
	}
}

// Create a single instance of GTMEventHandler
const gtmEventHandler = new GTMEventHandler();

// Export the instance
export default gtmEventHandler;
