import PlayIcon from './svg/play-icon';
import PauseIcon from './svg/pause-icon';
import styles from "./styles/audio-player.module.scss";
import type { MouseEventHandler } from 'react';
const PlayPauseButton = (props: {
  togglePlaying: MouseEventHandler<HTMLButtonElement>;
}) => {
  return <button className={styles.play} aria-label="Play/Pause Toggle" onClick={props.togglePlaying} data-sentry-component="PlayPauseButton" data-sentry-source-file="play-pause-button.tsx">
		{/* TODO: yikes is the accessible? what handles showing these, just the styles? */}
		<span className={styles.playIcon}><PlayIcon data-sentry-element="PlayIcon" data-sentry-source-file="play-pause-button.tsx" /></span>
		<span className={styles.pauseIcon}><PauseIcon data-sentry-element="PauseIcon" data-sentry-source-file="play-pause-button.tsx" /></span>
	</button>;
};
export default PlayPauseButton;