import type { KeyboardEventHandler, MouseEventHandler, KeyboardEvent, MouseEvent } from "react";
import { Fragment } from "react";
import styles from "./clearable-filters.module.scss";
type selectedValue = {
  label: string; // TODO: apparently you can pass in label OR name? let's pick one.
  /** @deprecated use label instead */
  name?: string;
  value: string;
  /** @deprecated */
  toolTip?: string;
};
export type IClearableFiltersProps = {
  //* @param facet - usually the name of the paramater you want to remove a value from
  groupName: string;
  facets?: Array<{
    name: string;
    selectedValues: selectedValue[];
  }>;
  handleClose: (event: (MouseEvent | KeyboardEvent), facet: string) => void; // TODO: rename this "handleClearOne" or something?
  handleClearAll?: MouseEventHandler & KeyboardEventHandler;
  selectedValues?: selectedValue[];
};
const ClearableFilters = (props: IClearableFiltersProps) => {
  // make this backwards compatible with single facet/selectedValues:
  const facets = props.facets ? props.facets : [{
    name: props.groupName,
    selectedValues: props.selectedValues
  }];
  const showClearAll = facets.some(facet => facet?.selectedValues?.length > 1); // yes, greater than 1, not zero
  return <Fragment key={props.groupName}>
		{facets.map(facet => <Fragment key={facet.name}>
				{facet.selectedValues.map(selectedValue => {
        return <button key={`facet-${selectedValue.value}-${facet.name}`} className={`${styles.clearableFilter} ${styles.activeFilter}`} value={selectedValue.value} onKeyDown={event => event.key === 'Enter' && props.handleClose(event, facet.name)} onClick={event => props.handleClose(event, facet.name)}>
							{selectedValue.label || selectedValue.name}
						</button>;
      })}
			</Fragment>)}
		{props.handleClearAll && showClearAll && <button key={`facet-clearall`} className={`${styles.clearableFilter} ${styles.activeFilter} ${styles.inverted}`} value={"clear-all"} onKeyDown={event => event.key === 'Enter' && props.handleClearAll(event)} onClick={props.handleClearAll}>
					Clear all
				</button>}
	</Fragment>;
};
export default ClearableFilters;