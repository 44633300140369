import LinkButton from "components/controls/links/link-button";
import ChevronR from "assets/icons/chevron-r.svg";
import styles from "./component-header.module.scss";
import MarkdownParser from "components/rich-text/markdown-parser";
interface IProps {
  description?: string;
  hasClampedWidth?: boolean;
  link?: {
    cta: string;
    url: string;
  };
  title: string;
}
const ComponentHeader = ({
  title,
  description,
  link,
  hasClampedWidth = true
}: IProps) => {
  const classes = hasClampedWidth ? `${styles.wrapper} ${styles.clampedWidth}` : styles.wrapper;
  return <div className={classes} data-sentry-component="ComponentHeader" data-sentry-source-file="index.tsx">
			<div className={styles.titleAndLinkWrapper}>
				<div>
					<h2 className={styles.title}><MarkdownParser data-sentry-element="MarkdownParser" data-sentry-source-file="index.tsx">{title}</MarkdownParser></h2>
				</div>
				{link?.url && link?.cta ? <div className={styles.cta}>
						<LinkButton href={link.url} text={link.cta} rightIcon={<ChevronR />} />
					</div> : null}
			</div>
			<p className={styles.description}><MarkdownParser data-sentry-element="MarkdownParser" data-sentry-source-file="index.tsx">{description}</MarkdownParser></p>
		</div>;
};
export default ComponentHeader;