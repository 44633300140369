import Select, { ActionMeta, components, MenuPlacement } from 'react-select';
import SelectOption from './option';
import customStyles from './customStyles';
import styles from "./select-component.module.scss";
import ScreenReaderOnly from "components/accesibility/screen-reader-only";
import { useId } from 'react';
const Input = components.Input as any; //If you can figure out the type to cast this to, please do.
const TextInput = props => <Input {...props} aria-activedescendant={undefined} data-sentry-element="Input" data-sentry-component="TextInput" data-sentry-source-file="index.tsx" />;
const SelectComponent = ({
  showFieldName = true,
  fieldName,
  id,
  name,
  options,
  isSearchable = true,
  isMulti = false,
  ...restProps
}: IProps) => {
  // Generate a unique ID if not provided
  const uniqueId = useId();
  const inputId = id || `select-${uniqueId}`;
  const components = {
    Option: SelectOption,
    Input: TextInput
  };
  return <div data-sentry-component="SelectComponent" data-sentry-source-file="index.tsx">
			{showFieldName ? <label className={styles.label} htmlFor={id}> {fieldName} </label> : <ScreenReaderOnly Element="label" text={fieldName} htmlFor={id} />}
			<Select instanceId={useId()} inputId={inputId} styles={customStyles} className={styles.select} options={options} name={name} {...restProps} isMulti={isMulti} isSearchable={isSearchable} components={components} data-sentry-element="Select" data-sentry-source-file="index.tsx" />
		</div>;
};
export interface IProps {
  fieldName: string;
  id: string;
  isClearable?: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  /** @deprecated */
  instanceID?: string;
  menuPlacement?: MenuPlacement;
  name?: string;
  onChange?: (newValue: any, actionMeta: ActionMeta<any>) => void;
  options: {
    value: string;
    label: string;
    count?: number;
  }[];
  placeholder?: string;
  showFieldName?: boolean;
  value?: any;
}
export default SelectComponent;