import SearchBar from 'components/controls/search-bar';
import { useEffect, useRef, useState } from "react";
import type { ChangeEventHandler } from 'react';
const SearchWrapper = ({
  baseUrl,
  placeholder,
  onChange,
  searchText
}: Props) => {
  const searchRef = useRef<any>(undefined);
  const formRef = useRef<any>(undefined);
  const [isSearching, setIsSearching] = useState(false);
  const handleSearch = () => {
    setIsSearching(true);
    formRef.current.submit();
  };
  useEffect(() => {
    searchRef?.current?.focus();
  }, []);
  return <form ref={formRef} action={baseUrl} method="GET" data-sentry-component="SearchWrapper" data-sentry-source-file="index.tsx">
			<SearchBar name={"q"} inputRef={searchRef} debounceTimeout={0} placeholderText={placeholder || "What would you like to find?"} onKeyDown={event => event.key === 'Enter' && handleSearch()} isSearching={isSearching} onButtonClick={handleSearch} initialQuery={searchText} onChange={onChange} data-sentry-element="SearchBar" data-sentry-source-file="index.tsx" />
		</form>;
};
export default SearchWrapper;
interface Props {
  placeholder?: string;
  baseUrl: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  searchText?: string;
}