/* The source of truth for hardcoded URL paths */
export const artCollectorRoute = `research-centers/leonard-a-lauder-research-center/research-resources/modern-art-index-project`;
export const visitRoute = `/plan-your-visit`
export const aboutRoute = '/about-the-met'
export * from "./route-for-document";

export const routes = {}

routes['annualReportPage'] = (slug: string) => `/support/annual-reports`;
routes['artCollector'] = (slug: string) => `/${artCollectorRoute}/${slug}`;
routes['article'] = (slug: string) => `/perspectives/${slug}`;
routes['audioFile'] = (slug: string) => `/audio-guide/audio/${slug}`;
routes['audioGuideLandingPage'] = (slug: string) => `/audio-guide`;
routes['audioPlaylist'] = (slug: string) => `/audio-guide/playlists/${slug}`;
routes['collectionLandingPage'] = (slug: string) => `/art/collection`;
routes['corporateSupport'] = (slug: string) => `/support/corporate-support`;
routes['crdCollection'] = (slug: string) => `/collections/${slug}`;
routes['department'] = (slug: string) => `${aboutRoute}/departments/${slug}`;
routes['diningLandingPage'] = (slug: string) => `/plan-your-visit/dining`;
routes['diningLocation'] = (slug: string) => '/plan-your-visit/dining';
routes['essay'] = (slug: string) => `/essays/${slug}`;
routes['exhibition'] = (slug: string) => `/exhibitions/${slug}`;
routes['exhibitionLandingPage'] = (slug: string) => `/exhibitions`;

routes['friendsGroup'] = (slug: string) => `/support/friends-groups/${slug}`;
routes['friendsGroupsLandingPage'] = (slug: string) => `/support/friends-groups`;
routes['galleryViewer'] = (slug: string) => `/gallery-viewer/${slug}`;
routes['givingResource'] = (slug: string) => `/support/planned-giving/resources/${slug}`;
routes['groupVisitLandingPage'] = (slug: string) => `/plan-your-visit/group-visits`;
routes['groupVisitType'] = (slug: string) => `/plan-your-visit/group-visits/${slug}`;
routes['homepage'] = (slug: string) => `/`;
routes['hostAnEventPage'] = (slug: string) => `/support/host-an-event`;
routes['hubPage'] = (slug: string) => `/hubs/${slug}`;

routes['journal'] = (slug: string) => `/met-publications/${slug}`;
routes['locationPage'] = (slug: string) => `${visitRoute}/${slug}`;
routes['membershipFAQPage'] = (slug: string) => `/support/membership/membership-faqs`;
routes['periodRoomInteractive'] = (slug: string) => `/kiosk/period-room/${slug}`;;
routes['perspectivesLandingPage'] = (slug: string) => `/perspectives`;
routes['plannedGivingLandingPage'] = (slug: string) => `/support/planned-giving`;
routes['planYourVisit'] = (slug: string) => `/plan-your-visit`;
routes['pressHomepage'] = (slug: string) => '/press';
routes['pressRelease'] = (slug:string) => `/press-releases/${slug}`;
routes['publication'] = (slug: string) => `/met-publications/${slug}`;
routes['publicationLandingPage'] = (slug: string) => `/met-publications`;
routes['researchCenter'] = (slug: string) => `/research-centers/${slug}`;

routes['series'] = (slug: string) => `/perspectives/series/${slug}`;
routes['supportersList'] = (slug: string) => `/support/${slug}`;
routes['supportLandingPage'] = (slug: string) => '/support';
routes['volunteer'] = (slug: string) => `/support/volunteer/${slug}`;
routes['volunteerLandingPage'] = (slug: string) => `/support/volunteer`;
routes['wayOfGiving'] = (slug: string) => `/support/planned-giving/${slug}`;
routes['welcomePage'] = (slug: string) => `/welcome`;
routes["policy"] = (slug: string) => `/policies/${slug}`;;
routes["pressRelease"] = (slug: string) => `/press-releases/${slug}`;
routes[`vacheronConstantinArtisanResidency`] = (slug: string) => `/vacheron-constantin-artisan-residency`;