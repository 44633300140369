"use client";

import { useState, useEffect } from 'react';
import styles from "./video-banner.module.scss";
import SanityImage from "@sanity-image";
import SanitySource from 'components/helper/sanity-source';
import MarkdownParser from "components/rich-text/markdown-parser";
import Link from "next/link";
import type { Image } from "@metmuseum/types";
const VideoWrapper = (props: {
  vimeoID: string;
}) => {
  return <iframe role="presentation" className={styles.vimeoContainer} src={`https://player.vimeo.com/video/${props.vimeoID}?speed=0&pip=0&loop=1&background=1&app_id=122963`} height="100%" width="100%" data-sentry-component="VideoWrapper" data-sentry-source-file="index.tsx" />;
};
const ImageWrapper = ({
  mobilePortrait,
  landscapeFallBack
}: ImageWrapperProps) => {
  return <div className={styles.image} data-sentry-component="ImageWrapper" data-sentry-source-file="index.tsx">
			<picture className={styles.picture} data-sentry-element="picture" data-sentry-source-file="index.tsx">
				{mobilePortrait && <SanitySource media="(max-width: 700px)" image={mobilePortrait} />}
				{landscapeFallBack && <SanitySource media="(min-width: 700px)" image={landscapeFallBack} />}
				<SanityImage fill={true} sizes={"100vw"} priority={true} image={mobilePortrait} data-sentry-element="SanityImage" data-sentry-source-file="index.tsx" />
			</picture>
		</div>;
};
interface ImageWrapperProps {
  landscapeFallBack: Image;
  mobilePortrait: Image;
}
const VideoBanner = ({
  videoBanner,
  disableVideo
}: Props) => {
  const [windowWidth, setWindowWidth] = useState(null);
  const {
    vimeoID,
    title,
    cta,
    link,
    mobilePortrait,
    landscapeFallBack
  } = videoBanner;
  useEffect(() => {
    setWindowWidth(window?.innerWidth);
  }, []);
  return <section className={`${styles.videoBanner} ${styles["component"]}`} data-sentry-component="VideoBanner" data-sentry-source-file="index.tsx">
			{!disableVideo && windowWidth > 600 && <VideoWrapper vimeoID={vimeoID} />}
			<ImageWrapper mobilePortrait={mobilePortrait} landscapeFallBack={landscapeFallBack} data-sentry-element="ImageWrapper" data-sentry-source-file="index.tsx" />

			<div className={styles.copy}>
				<h1 className={styles.title}>
					<MarkdownParser data-sentry-element="MarkdownParser" data-sentry-source-file="index.tsx">{title}</MarkdownParser>
				</h1>
				<Link href={link} className={`button secondary ${styles.button}`} data-sentry-element="Link" data-sentry-source-file="index.tsx">
					{cta}
				</Link>
			</div>
		</section>;
};
interface Props {
  disableVideo?: boolean;
  videoBanner: {
    cta: string;
    landscapeFallBack: Image;
    link: string;
    mobilePortrait?: Image;
    title: string;
    vimeoID: string;
  };
}
export default VideoBanner;