import imageUrlBuilder from '@sanity/image-url';
import { sanityClient } from 'lib/sanity.server';
import { routeForDocumentWithSubsection } from 'lib/routes';

/* all focusable elements */
export const focusableElements = 'button, input, select, textarea, [tabindex]:not([tabindex="-1"]), a[href]';

export interface ILink {
	internalLink?: {
		reference: {
			_type: string,
			slug: {current:string}
		}
		subsection?: string
	}
	link?: string,
	href?: string
}

//A function that returns a URL from a Sanity Image Object - or just returns the string if an URL string is passed.
export const urlFor = (source: any, quality: number = 75, width: number = 600): string | null => {
	if (isValidUrl(source)) {	//If a URL was passed instead of an object just return it.
		return source;
	} else if (source?._type === "image") {
		return imageUrlBuilder(sanityClient({}))
			?.image(source)
			?.auto("format")
			?.width(width)
			?.quality(quality)
			?.url()
	} else {
		return null;
	}
}

export const isValidUrl = (urlString: string): boolean => {
	const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
		'(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
	return !!urlPattern.test(urlString);
}

export const normalizeBreadcrumbs = (string: string): string => {
	// old school safety check because browsers without replaceAll might not support ?. either
	if (typeof string.replaceAll === "function") {
		return string
			.replaceAll('Leonard a Lauder', 'Leonard A Lauder')
			.replaceAll("Met Publications", 'MetPublications')
			.replaceAll("the Met", "The Met")
	}
	return string
}


//Pass a Link object ({interalLink,link}) and return a string
/* this function is a mess because we made several different Link Schemas. We need to sort this out ASAP */
export const getUrlString = (link: ILink): string => {
	return link?.internalLink?.reference ? routeForDocumentWithSubsection({
		...link.internalLink.reference,
		subsection: link?.internalLink?.subsection
	}) : link?.link || link?.href;
}

/* Makes a list of strings into an Oxford comma-ized string */
export const commaize = (list: any[]): string => {
	if(!list?.length) {
		return '';
	}
	
	const uniques = [...new Set(list)];
	if (uniques.length == 1) {
		return uniques[0];
	}
	else if (uniques.length == 2) {
		return `${uniques[0]} and ${uniques[1]}`;
	}
	else {
		return `${uniques.slice(0, uniques.length-1).join(', ')}, and ${uniques[uniques.length - 1]}`;
	}
}

// Q&D helper to convert markdown italics without an ENORMOUS library or React (ex. an API endpoint)
export const convertMarkdownItalicsToHtml = (markdown: string): string => {
	if (!markdown) return ""

	const italicRegex = /(?<!\*)\*(?![*\s])(?:[^*]*[^*\s])?\*(?!\*)/igm
	const matches = markdown.match(italicRegex) || []
	let formattedText = markdown
	matches.forEach((match: string) => {
		formattedText = formattedText.replace(match, `<em>${match.substring(1, match.length - 1)}</em>`)
	})
	return formattedText
}

/* Right now the only markdown we really use is * or **, so this is super basic. But let's extend it if we need to */
export const removeMarkdown = (string: string): string => {
	if (!string) return "";
	return string?.replace(/[*]/g, "");
}

export const isNumeric = function (obj: any): boolean {
	if (typeof obj == "number") return true;
	if (typeof obj == "string") return false; 
	return !isNaN(obj) && !isNaN(parseFloat(obj));
}

export const humanizeFileSize = (bytes: number): string => {
	if (bytes === 0) {
		return "0.00 B";
	}

	const e = Math.floor(Math.log(bytes) / Math.log(1024));
	return (bytes / Math.pow(1024, e)).toFixed(2) +
			' ' + ' KMGTP'.charAt(e) + 'B';
};
