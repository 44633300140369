import { useTranslations } from "next-intl";
const PlayIcon = (props: {
  className?: string;
}) => {
  const t = useTranslations();
  return <svg className={props.className || ""} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" data-sentry-element="svg" data-sentry-component="PlayIcon" data-sentry-source-file="play-icon.tsx">
		<title>{t('play')}</title>
		<path d="M 6 3 A 1 1 0 0 0 5 4 A 1 1 0 0 0 5 4.0039062 L 5 15 L 5 25.996094 A 1 1 0 0 0 5 26 A 1 1 0 0 0 6 27 A 1 1 0 0 0 6.5800781 26.8125 L 6.5820312 26.814453 L 26.416016 15.908203 A 1 1 0 0 0 27 15 A 1 1 0 0 0 26.388672 14.078125 L 6.5820312 3.1855469 L 6.5800781 3.1855469 A 1 1 0 0 0 6 3 z" data-sentry-element="path" data-sentry-source-file="play-icon.tsx"></path>
	</svg>;
};
export default PlayIcon;