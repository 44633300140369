export const parseObjectIDFromAbsoluteURL = (urlString: string): number | null => {
	let objectID;
	try {
		// assume it's to an Object Detail Page, so last path segment is its ID
		objectID = parseInt(
			new URL(urlString)
				.pathname
				.split("/")
				.pop()
		)
		if (typeof objectID === 'number' && !isNaN(objectID)) return objectID
	} catch (error) {
		// new URL will throw an error on an invalid string, so let's catch it
		console.debug(error)
		console.warn(`Expected absolute URL to object detail page, got: ${urlString}`)
	}

	return null
}
