import React from "react";
import styles from "./styles/audio-player.module.scss";
import MarkdownParser from "components/rich-text/markdown-parser";
interface IProps {
  id: string;
  isActive: boolean;
  title: string;
  handleTrackChange: (string) => unknown;
}
class PlaylistTrack extends React.PureComponent<IProps> {
  handleTrackChange() {
    this.props.handleTrackChange(this.props.id);
  }
  render() {
    return <li onClick={this.handleTrackChange.bind(this)} className={`${styles.playlistTrack} ${this.props.isActive ? styles.isActive : ""}`} data-sentry-component="PlaylistTrack" data-sentry-source-file="playlistTrack.tsx">
				{/* {!!this.props.image &&
     Lets leave this out until we write something to handle the fact that some images are Sanity Objects and others are from CRD
     <Image
     	className="audio-player__playlist-track-thumbnail"
     	alt={this.props.image.alt}
     	src={this.props.image.w280}
     />
     } */}
				<div className={styles.playlistTrackTitle}><MarkdownParser data-sentry-element="MarkdownParser" data-sentry-source-file="playlistTrack.tsx">{this.props.title}</MarkdownParser></div>
				{/* <!-- <div> track length goes here if we have it </div> --> */}
			</li>;
  }
}
export default PlaylistTrack;