import { SanityPerson, ElasticPerson } from "@metmuseum/types";

export const formatFullName = (person: SanityPerson | ElasticPerson & { title: string }): string => {
	const { firstName, middleName, lastName, titlePrefix, titleSuffix, under18 } = person || {};

	if (under18) {
		return `${firstName || ""}`.trim();
	}

	const fullNameParts = [titlePrefix, firstName, middleName, lastName, titleSuffix].filter(Boolean);

	return fullNameParts.join(" ").trim();
};
