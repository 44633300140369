import PlaylistTrack from "./playlistTrack";
import { useTranslations } from "next-intl";
import styles from "./styles/audio-player.module.scss";
import type { IAudioPlayerTrack } from "@metmuseum/types";
interface IProps {
  currentTrack: IAudioPlayerTrack;
  handleTrackChange: (id: string) => void;
  tracks: IAudioPlayerTrack[];
}
const Playlist = (props: IProps) => {
  const t = useTranslations();
  return <ol className={styles.playlist} data-sentry-component="Playlist" data-sentry-source-file="playlist.tsx">
		{props.tracks.length && <h4 className={styles.playlistTitle}>{t('playlist')}</h4>}
		{props.tracks.map(playlistTrack => <PlaylistTrack isActive={playlistTrack.id === props.currentTrack.id} {...playlistTrack} handleTrackChange={props.handleTrackChange} key={playlistTrack.id} />)}
	</ol>;
};
export default Playlist;