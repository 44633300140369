import { useState } from "react";
import RichText from "components/rich-text";
import styles from "./styles/audio-player.module.scss";
import UpChevron from "assets/icons/chevron-up.svg";
import { useTranslations } from "next-intl";
import type { PortableTextBlock } from "@portabletext/types";
const Transcript = ({
  transcript
}: {
  transcript: PortableTextBlock[] | string;
}) => {
  const hasTranscript = !!transcript?.length;
  const [transcriptIsOpen, setTranscriptIsOpen] = useState(true);
  const t = useTranslations();
  const handleTranscriptToggle = e => {
    e.preventDefault();
    setTranscriptIsOpen(!transcriptIsOpen);
  };
  if (hasTranscript) {
    return <div>
				<div className={`${styles.transcriptSection} ${transcriptIsOpen ? styles.transcriptIsOpen : ""}`}>
					<div className={styles.transcriptWrapper}>
						<div className={styles.transcript}>
							{/* TODO: what to do with tabIndex="0" intentions?
        <RichText value={transcript} tabIndex="0" />
        */}
							<RichText value={transcript} />
						</div>
					</div>

					<button className={styles.transcriptToggle} onClick={handleTranscriptToggle} style={{
          background: "none",
          border: 0,
          color: "currentColor"
        }}>
						<span className={styles.transcriptToggleIcon}><UpChevron /></span>
						<span className={styles.transcriptToggleText}>{transcriptIsOpen ? t('hideTranscript') : t('viewTranscript')}</span>
					</button>
				</div>
			</div>;
  }
};
export default Transcript;