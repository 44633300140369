"use client";

import React, { useEffect, useState } from 'react';
import MinimalHeader from "components/headers/minimal-header";
import { useSearchParams, useRouter, usePathname } from 'next/navigation';
import { Filters } from "./_filters";
import PaginationControls from 'components/collection-search/pagination';
import MediaGrid from "components/perspectives/media-grid";
import noResultsStyles from 'components/perspectives/search/no-results.module.scss';
import { SITE_SEARCH_PER_PAGE } from 'lib/constants.mjs';
import ArticleCard from 'components/cards/article-card';
import type { SanityArticleElasticDocument } from "@metmuseum/types";
import { routeForDocument } from "lib/routes";
import { SearchTotalHits } from '@elastic/elasticsearch/lib/api/types';
import { epochTimeToUTCYYYYMMDD } from "helpers/date-time";
export const PerspectivesAll = ({
  initialResults,
  topicFilters,
  departmentFilters,
  latestArticles
}: IPerspectivesFiltersProps) => {
  const router = useRouter();
  const pathName = usePathname();
  const searchParams = useSearchParams();
  const resultsPerPage = SITE_SEARCH_PER_PAGE;

  //state for the selected media checkboxes
  const [selectedMediaType, setSelectedMediaType] = useState(searchParams.get("type")?.split(",") || []);

  // Add state for the selected topic
  const [selectedTopic, setSelectedTopic] = useState(searchParams.get("topic") || null);

  // Add state for the selected department
  const [selectedDepartment, setSelectedDepartment] = useState(searchParams.get("department") || null);

  //add a state to store the fetched media
  const [pageNumber, setPageNumber] = useState(searchParams.get("page") ? parseInt(searchParams.get("page")) : 1);
  const totalCount = typeof initialResults.hits.total === "number" ? initialResults.hits.total : initialResults.hits.total.value;

  //when they click a media checkbox, update the selectedMediaType array 
  //(which controls the checkboxes, url, and API call)
  const handleCheckboxChange = e => {
    const value = e.target.value;
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedMediaType([...selectedMediaType, value]);
    } else {
      setSelectedMediaType(selectedMediaType.filter(media => media !== value));
    }
    setPageNumber(1);
  };
  const handleRemoveSelection = e => {
    const value = e.target.value;
    setSelectedMediaType(selectedMediaType.filter(media => media !== value));
    setPageNumber(1);
  };
  const handlePaginationChange = e => {
    const offset = e.currentTarget.value;
    setPageNumber(pageNumber + offset / resultsPerPage);
  };

  //handle writing state to URL
  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    selectedMediaType.length ? params.set("type", selectedMediaType.toString()) : params.delete("type");
    selectedTopic ? params.set("topic", selectedTopic.toLowerCase()) : params.delete("topic");
    selectedDepartment ? params.set("department", selectedDepartment.toLowerCase()) : params.delete("department");
    pageNumber !== 1 ? params.set("page", pageNumber.toString()) : params.delete("page");
    router.push(`${pathName}?${params.toString()}`, {
      scroll: !!params.get("page")
    });
  }, [searchParams, selectedMediaType, selectedTopic, selectedDepartment, pageNumber, router, pathName]);
  return <main data-sentry-component="PerspectivesAll" data-sentry-source-file="perspectives-all-app.tsx">
			<MinimalHeader heading="All Articles, Audio, and Videos" data-sentry-element="MinimalHeader" data-sentry-source-file="perspectives-all-app.tsx" />
			<Filters selectedMediaType={selectedMediaType} handleCheckboxChange={handleCheckboxChange} topicFilters={topicFilters} departmentFilters={departmentFilters} selectedTopic={selectedTopic} selectedDepartment={selectedDepartment} handleClearAll={() => {
      setPageNumber(1);
      setSelectedDepartment(null);
      setSelectedMediaType([]);
      setSelectedTopic(null);
    }} handleClearTopic={() => {
      setSelectedTopic(null);
      setPageNumber(1);
    }} handleClearDepartment={() => {
      setSelectedDepartment(null);
      setPageNumber(1);
    }} handleRemoveSelection={handleRemoveSelection} handleTopicSelectChange={selectedOption => {
      // @ts-expect-error TODO: fix typescript hell with react-select and prop drilling
      setSelectedTopic(selectedOption ? selectedOption?.value?.toLowerCase() : null);
      setPageNumber(1);
    }} handleDepartmentSelectChange={selectedOption => {
      // @ts-expect-error TODO: fix typescript hell with react-select and prop drilling
      setSelectedDepartment(selectedOption ? selectedOption?.value.toLowerCase() : null);
      setPageNumber(1);
    }} data-sentry-element="Filters" data-sentry-source-file="perspectives-all-app.tsx" />

			{totalCount > 0 && <section className="productive-component">
				<MediaGrid>
					{initialResults.hits.hits.map(({
          _source
        }: {
          _source: SanityArticleElasticDocument;
        }) => <ArticleCard image={_source.cardImage} contributors={_source?.contributors?.map(contributor => ({
          person: contributor
        }))} date={epochTimeToUTCYYYYMMDD(_source.publicationDate)} key={_source.sanityId} link={routeForDocument({
          _type: "article",
          slug: {
            current: _source.slug
          }
        })} sideBySide={true} textDescription={_source.description} title={_source.title} type={_source.type} />)}
				</MediaGrid>
			</section>}

			{totalCount < 1 && <div key="latestArticles">
				<section className={noResultsStyles.container}>
					<h4 className={noResultsStyles.heading}>No results found</h4>
					<p>Please try using a different combination of filters.</p>
				</section>
				<section className="productive-component">
					<h2 style={{
          marginBottom: 'calc(16px + 0.5vw)'
        }}>Recently Published</h2>
					<MediaGrid>
						{latestArticles.hits.hits.map(({
            _source
          }: {
            _source: SanityArticleElasticDocument;
          }) => <ArticleCard image={_source.cardImage} contributors={_source?.contributors?.map(contributor => ({
            person: contributor
          }))} date={epochTimeToUTCYYYYMMDD(_source.publicationDate)} key={_source.sanityId} link={routeForDocument({
            _type: "article",
            slug: {
              current: _source.slug
            }
          })} sideBySide={true} textDescription={_source.description} title={_source.title} type={_source.type} />)}
					</MediaGrid>
				</section>
			</div>}

			<PaginationControls offset={resultsPerPage * (pageNumber - 1)} // this api starts at page 1
    handlePaginationChange={handlePaginationChange} perPage={resultsPerPage} totalResults={totalCount} data-sentry-element="PaginationControls" data-sentry-source-file="perspectives-all-app.tsx" />
		</main>;
};
interface IPerspectivesFiltersProps {
  departmentFilters?: any[];
  initialResults: {
    hits: {
      hits: any[];
      total: number | SearchTotalHits;
    };
  };
  latestArticles?: {
    hits: {
      hits: any[];
    };
  };
  topicFilters?: any[];
}