import { useTranslations } from "next-intl";
const PauseIcon = (props: {
  className?: string;
}) => {
  const t = useTranslations();
  return <svg className={props.className || ""} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" data-sentry-element="svg" data-sentry-component="PauseIcon" data-sentry-source-file="pause-icon.tsx">
		<title>{t('pause')}</title>
		<path d="M 8 4 C 6.895 4 6 4.895 6 6 L 6 24 C 6 25.105 6.895 26 8 26 L 10 26 C 11.105 26 12 25.105 12 24 L 12 6 C 12 4.895 11.105 4 10 4 L 8 4 z M 20 4 C 18.895 4 18 4.895 18 6 L 18 24 C 18 25.105 18.895 26 20 26 L 22 26 C 23.105 26 24 25.105 24 24 L 24 6 C 24 4.895 23.105 4 22 4 L 20 4 z" data-sentry-element="path" data-sentry-source-file="pause-icon.tsx"></path>
	</svg>;
};
export default PauseIcon;