export * from "./fetches";
export * from "./check-egyptian-period"
export * from "./model";

export const getObjectPagePath = (objectID: number): string => {
	return `/art/collection/search/${objectID}`;
}

// there is a list of objects where we shouldn't display loan info for
// only if they are in object packages of 268 or 793 
// (collectionIDs array has the object package IDs this item is in)
// and is also a returned loan (objectStatusID of 12)
export const shouldShowLoanInfo = (collectionIDs: number[], objectStatusID: number) => {
	if (objectStatusID === 12) { //Returned Loan.
		//Don't show loan info for objects in either of these packages.
		const hasTargetIDs = collectionIDs.includes(268) || collectionIDs.includes(793);
		return !hasTargetIDs;
	}
	return true;
};


//custom title class based on word count
export const getTitleSize = (title?: string): string => {
	const wordCount = title?.split(' ')?.length || 0;

	if (wordCount >= 34) return 'veryLongTitle';
	if (wordCount > 20) return 'longTitle';
	return 'regularTitle';
}

//TODO we probably will remove this logic in favor of something VH based.
//custom label class based on title size
export const getLabelMaxLines = (titleSize) => {
	switch (titleSize) {
		case 'longTitle':
			return 8;
		case 'veryLongTitle':
			return 6;
		default:
			return 10;
	}
}

//objects with objectStatusID of 0, 5, 12, 13 are loans to the Met (both currently displayed and returned)
export const checkLoanStatus = (objectStatusID: number) => {
	return [0, 5, 12, 13].includes(objectStatusID);
};
