import React from "react";
const YoutubePlayer = ({
  youtubeId,
  autoplay,
  loop,
  controls,
  playlistId
}: Props) => {
  if (!youtubeId && !playlistId) {
    return null;
  }
  const styles = {
    aspectRatio: '16 / 9',
    display: 'block',
    width: '100%'
  };
  const autoPlayString = autoplay ? `&autoplay=1&mute=1` : ``;
  const loopString = loop ? `&loop=1&playlist=${youtubeId}` : '';
  const controlsString = controls ? `&controls=1` : '';
  const embedUrl = youtubeId ? `https://www.youtube.com/embed/${youtubeId}?modestbranding=1${autoPlayString}${loopString}${controlsString}` : `https://www.youtube.com/embed/?listType=playlist&list=${playlistId}&modestbranding=1${autoPlayString}${loopString}${controlsString}`;
  return <iframe style={styles} loading="lazy" src={embedUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen data-sentry-component="YoutubePlayer" data-sentry-source-file="index.tsx" />;
};
type Props = {
  youtubeId?: string;
  autoplay?: string;
  loop?: string;
  controls?: string;
  playlistId?: string;
};
export default YoutubePlayer;