import TabDrawers from "components/tab-drawer";
import styles from "./search-section.module.scss";
import SearchWrapper from "./search-wrapper";
import { useEffect, useState } from "react";
import CloseIcon from "assets/icons/close-x.svg";
const SearchSection = ({
  toggleVisible,
  isVisibleOnLoad = false
}: SearchSectionProps) => {
  const [classes, setClasses] = useState(`${styles.wrapper} ${isVisibleOnLoad ? styles.isVisible : ""}`);
  const [searchText, setSearchText] = useState(undefined);
  const handleSearchTextChange = event => {
    setSearchText(event.target.value);
  };
  useEffect(() => {
    setClasses(`${styles.wrapper} ${styles.isVisible}`);
  }, []);
  return <div className={classes} data-sentry-component="SearchSection" data-sentry-source-file="index.tsx">
			<TabDrawers tabBodyWrapperClass={styles.tabDrawer} hasDivider={false} tabs={[{
      name: "Search art",
      body: <div className={styles.search}>
							<SearchWrapper baseUrl={`/art/collection/search`} onChange={handleSearchTextChange} searchText={searchText} />
						</div>
    }, {
      name: "Search the website",
      body: <div className={styles.search}>
							<SearchWrapper baseUrl={`/search-results`} onChange={handleSearchTextChange} searchText={searchText} />
						</div>
    }]} inputGroupName={"site-search-tabs"} screenreaderLegendText="Provides options to perform a text search of either The Met's online collection or general website content." data-sentry-element="TabDrawers" data-sentry-source-file="index.tsx" />
			<button aria-label="Close Search" title={"Close Search"} onClick={() => toggleVisible(false)} className={styles.close}>
				<CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="index.tsx" />
			</button>
			
		</div>;
};
type SearchSectionProps = {
  toggleVisible: (arg0: boolean) => void;
  isVisibleOnLoad: boolean;
};
export default SearchSection;