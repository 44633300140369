"use client";

import { useTranslations } from "next-intl";
import AudioPlayerClient from "./client-component";
import type { IAudioPlayerProps } from "@metmuseum/types";
import type { GTMEventHandler } from "lib/gtmEventHandler";
const AudioPlayer = (props: IAudioPlayerProps<GTMEventHandler>) => {
  const t = useTranslations();
  return <AudioPlayerClient {...props} t={t} data-sentry-element="AudioPlayerClient" data-sentry-component="AudioPlayer" data-sentry-source-file="index.tsx" />;
};
export default AudioPlayer;