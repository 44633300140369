"use client";

import { useState } from "react";
import CopyIcon from "assets/icons/copy.svg";
import styles from "./copy-to-clipboard.module.scss";
import Button from "components/controls/buttons";
import gtmEventHandler from 'lib/gtmEventHandler';
const copyToClipboard = (text: string) => {
  if (typeof navigator !== "undefined" && typeof navigator?.clipboard?.writeText !== "undefined") {
    navigator.clipboard.writeText(text);
    return true;
  }
  return false;
};
interface IProps {
  text: string;
  analytics?: {
    event: string;
    Button_Clicked?: string;
    Content_Type: string;
  };
}
const CopyToClipboard = ({
  text,
  analytics
}: IProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const handleCopy = () => {
    const success = copyToClipboard(text);
    setIsCopied(success);
    setTimeout(() => setIsCopied(false), 2000);
    if (success && analytics) {
      gtmEventHandler.pushToDataLayer(analytics);
    }
  };
  return <div className={styles.copyToClipboard} data-sentry-component="CopyToClipboard" data-sentry-source-file="index.tsx">
			{!isCopied && <Button appearance="tertiary" onClick={handleCopy}>
				Copy to clipboard <CopyIcon className={styles.copyIcon} />
			</Button>}
			{isCopied && <div className={`${styles.confirmation} tertiary button`}>Copied ✓</div>}
		</div>;
};
export default CopyToClipboard;