"use client";

import { ReactNode, useRef, useState } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import { focusableElements } from "helpers/helpers";
import styles from "./shelf.module.scss";
import Arrow from "assets/icons/arrow.svg";
const bufferPixels = 40; //A pixel value that represents a buffer for figuring out start/end position.

const Shelf = ({
  children,
  style = null,
  ...restProps
}: Props) => {
  const [showNextButton, setShowNextButton] = useState(false);
  const [showPreviousButton, setShowPreviousButton] = useState(false);
  const ref = useRef<HTMLDivElement>(undefined);
  const {
    events
  } = useDraggable(ref);
  const handleKeyDown = e => {
    e = e || window.event;
    if (e.keyCode == '40') {
      const lastChild = ref.current.lastElementChild;
      lastChild.querySelector<HTMLInputElement>(focusableElements)?.focus();
    }
  };
  const handleScrollTrigger = (forward = true) => {
    const newPosition = forward ? ref.current.scrollLeft + ref.current?.offsetWidth : ref.current?.scrollLeft - ref.current?.offsetWidth;
    ref.current.scrollTo({
      left: newPosition,
      behavior: "smooth"
    });
  };
  const handleBackArrowClick = () => handleScrollTrigger(false);
  const handleForwardArrowClick = () => handleScrollTrigger();
  const handleShowingArrows = () => {
    const sliderRef = ref.current;
    //If there is less than $buffer pixels to move left - hide the "Prev" Arrow.
    setShowPreviousButton(sliderRef?.scrollLeft > bufferPixels);
    //If there is less than $buffer pixels to the right - hide the "Next" 
    setShowNextButton(sliderRef?.scrollLeft + sliderRef.offsetWidth < sliderRef.scrollWidth - bufferPixels);
  };
  return <div {...restProps} className={styles.wrapper} onKeyDown={handleKeyDown} data-sentry-component="Shelf" data-sentry-source-file="index.tsx">
			<div className={styles.accessibilityMessage}>
				<div>A slider containing {children?.length || "zero"} items.<br />
				Press the down key to skip to the last item.</div>
			</div>

			<div onMouseOver={handleShowingArrows} onScroll={handleShowingArrows} className={styles.slider} {...events} ref={ref} style={style}>
				{children}
			</div>

			{showPreviousButton && <button aria-label="Previous button" onClick={handleBackArrowClick} className={`${styles.button} ${styles.backButton}`}>
				<Arrow />
			</button>}

			{showNextButton && <button aria-label="Next button" onClick={handleForwardArrowClick} className={`${styles.button} ${styles.forwardButton}`}>
				<Arrow />
			</button>}
		</div>;
};
interface Props {
  children: ReactNode[];
  className?: string;
  style?: object;
}
export default Shelf;