import React from 'react';
import PropTypes from 'prop-types';
import styles from "components/controls/select-component/select-component.module.scss";
const SelectOption = ({
  innerRef,
  innerProps,
  data,
  isDisabled
}) => {
  return !isDisabled ? <div aria-disabled={isDisabled} ref={innerRef} {...innerProps} className={styles.option} data-sentry-component="SelectOption" data-sentry-source-file="index.jsx">
			{data.label} {data.count ? <span className={styles.count}>({data.count})</span> : ""}
		</div> : null;
};
SelectOption.propTypes = {
  data: PropTypes.object,
  innerProps: PropTypes.object,
  innerRef: PropTypes.func,
  isFocused: PropTypes.bool,
  isDisabled: PropTypes.bool
};
export default SelectOption;